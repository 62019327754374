<form novalidate  >
  <nsc-system-message></nsc-system-message>

  <nsc-card *ngIf="content.welcomeMessage">
    <nsc-card-title>School Notifications</nsc-card-title>
    <nsc-card-content>
        <div class="nsc-welcome-message ql-editor" [innerHTML]="content.welcomeMessage | safeHtml:'html'">
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md" *ngIf="content.welcomeMessage"></nsc-spacer>

  <nsc-card>
    <nsc-card-title>Clearinghouse Notifications</nsc-card-title>
    <nsc-card-content>
      <ul class="nsc-clearinghouse-notif">
        <li><b>Electronic Transcripts -</b> must be retrieved <b>using a desktop or laptop computer</b> due to security features applied to the document. Mobile device and tablet access to the Download Center is blocked.</li>
         <li><b>Payment -</b> if a cost is involved, payment will be accepted with any major credit card or debit card. Your credit or debit card is not charged until your school sends your transcript(s). However, if you use a debit card, your bank may put a hold on your funds when we pre-authorize your payment. If you have any questions about a pre-authorization, please contact your bank.</li>
      </ul> 
        <div>
          <button mat-raised-button id="schoolselect_addprocess" class="nsc-btn__right" color="primary"
          type="button" (click)="redirectToProcessFlowLink()">Learn How the Process Works
          <mat-icon aria-hidden="true">chevron_right</mat-icon></button>
        </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-raised-button
      id="ordertranscript_button"
      class="nsc-form-actions__button nsc-btn__right button-big"
      color="primary"
      type="button"
      (click)="continue()"> Order Transcript(s) <mat-icon aria-hidden="true">chevron_right</mat-icon></button>
  </div>
  <nsc-spacer size="md"></nsc-spacer>
  <div class="col-12 d-flex justify-content-center">
      <a id="view_transcript_status" aria-label="View Transcript Order Status" [routerLink]="[]" (click)="openConfirmationDialog()">View Transcript Order Status</a>
  </div>
</form>
