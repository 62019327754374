<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Personal Information</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12 col-md-4 ">
          <nsc-form-field-input idx="firstNameField" autocomplete="given-name" controlName="nameFirst" maxlength="35" placeholder="First Name" [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-4">
          <nsc-form-field-input autocomplete="additional-name" controlName="nameMiddle" maxlength="35" placeholder="Middle Name" [group]="formGroup"
            [required]="false"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-4">
          <nsc-form-field-input autocomplete="family-name" idx="nameLast_field" controlName="nameLast" maxlength="35" placeholder="Last Name" [group]="formGroup"></nsc-form-field-input>
        </div>

        <div class="col-12 col-md-4">
          <nsc-form-field-input placeholder="Date of Birth" idx="dob_field" controlName="dob" hint="MM/DD/YYYY" [group]="formGroup" [mask]="masks.date"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-10 col-lg-8">
          <nsc-form-field-toggle idx="nameChanged_field" controlName="nameChanged" label="Has your name changed since attending school?" [disabled]="formGroup.controls['nameFirst'].disabled && !values.verifiedSamlStudent"
            [group]="formGroup"></nsc-form-field-toggle>
            
         <div *ngIf="show.allowStudentName"> 
          <nsc-form-field-toggle idx="studentName_field" controlName="allowStudentName" helpTitlePopUp= "{{values.studentNameLabel}}" help="{{ livedNameToolTipMsg }}" [disabled]="formGroup.controls['nameFirst'].disabled" label="Do you have a {{values.studentNameLabel}}?" [group]="formGroup"></nsc-form-field-toggle>
        </div> 
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <div *ngIf="formGroup.controls['nameChanged'].value === values.yes">
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-card>
      <nsc-card-title>My Name While Attending School</nsc-card-title>
      <nsc-card-content>
        <div class="row">
          <div class="col-12 col-md-4">
            <nsc-form-field-input idx="nameChangedFirstField" controlName="nameChangedFirst" maxlength="35" placeholder="First Name" [group]="formGroup"></nsc-form-field-input>
          </div>
          <div class="col-12 col-md-4">
            <nsc-form-field-input idx="nameChangedMiddleField" controlName="nameChangedMiddle" maxlength="35" placeholder="Middle Name" [group]="formGroup" [required]="false"></nsc-form-field-input>
          </div>
          <div class="col-12 col-md-4">
            <nsc-form-field-input idx="nameChangedLastField" controlName="nameChangedLast" maxlength="35" placeholder="Last Name" [group]="formGroup"></nsc-form-field-input>
          </div>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <div *ngIf="show.allowStudentName && formGroup.controls['allowStudentName'].value === values.yes">
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-card>
      <nsc-card-title>My {{values.studentNameLabel}}</nsc-card-title>
      <nsc-card-content>
        <div class="row">
          <div class="col-12 col-md-4">
            <nsc-form-field-input controlName="livedNameFirst" idx="livedName_first"  maxlength="35" placeholder="First Name" [group]="formGroup"></nsc-form-field-input>
          </div>
          <div class="col-12 col-md-4">
            <nsc-form-field-input controlName="livedNameMiddle" idx="livedName_middle" maxlength="35" placeholder="Middle Name" [group]="formGroup" [required]="false"></nsc-form-field-input>
          </div>
          <div class="col-12 col-md-4">
            <nsc-form-field-input controlName="livedNameLast" idx="livedName_last" maxlength="35" placeholder="Last Name" [group]="formGroup"></nsc-form-field-input>
          </div>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <nsc-spacer size="md"></nsc-spacer>

  <nsc-card>
    <nsc-card-title note="{{note}}">Student Identification Information</nsc-card-title>
    <nsc-card-content>
      <div class="row" *ngIf="show.studentIdRow || show.ssnRow">
          <div class="row col-12" *ngIf="show.studentIdRow">
            <div *ngIf="show.studentIdPrimary && show.studentIdConfirm" class="col-12">
              <nsc-form-field-confirm autocomplete="on" hint="Dashes are not allowed" idx="studentIdConfirm_field" controlNameConfirm="studentIdConfirm" idx="studentIdPrimary_field" controlNamePrimary="studentIdPrimary"  minlength="{{ values.studentIdMinLength }}" maxlength="{{ values.studentIdMaxLength }}"
                placeholder="{{ content.labelStudentId }}" [group]="formGroup" [requiredConfirm]="require.studentIdConfirm" [requiredPrimary]="require.studentIdPrimary"></nsc-form-field-confirm>
            </div>
            <div *ngIf="show.studentIdPrimary && !show.studentIdConfirm" class="col-12 col-md-6 col-lg-4">
              <nsc-form-field-input hint="Dashes are not allowed" idx="studentIdPrimary_field" controlName="studentIdPrimary" minlength="{{ values.studentIdMinLength }}"  maxlength="{{ values.studentIdMaxLength }}" placeholder="{{ content.labelStudentId }}"
                [group]="formGroup" [required]="require.studentIdPrimary"></nsc-form-field-input>
            </div>
          </div>
  
          <div [ngClass]="require.one ? '' : 'nsc-hidden'" *ngIf="show.studentIdRow && show.ssnRow ">
            <div class="col-12" *ngIf="require.one" >
              <div class="ynsc-or" aria-label="or"><strong>OR</strong></div>
            </div>
          </div>
  
  
          <div class="row col-12" *ngIf="show.ssnRow">
            <div class="col-12" *ngIf="show.ssn">
              <nsc-form-field-confirm idx="req_identification_ssn" controlNameConfirm="ssnConfirm" controlNamePrimary="ssnPrimary" hint="XXX-XX-XXXX" maxlength="11"
                placeholder="Social Security Number" type="password" [group]="formGroup"  [requiredConfirm]="require.ssnConfirm"
                [requiredPrimary]="require.ssnPrimary"></nsc-form-field-confirm>
            </div>
          </div>
  
          <div class="col-12">
            <hr class="nsc-hr" style="margin-top:5px;margin-bottom: 5px;">
          </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="show.pathQuestion">
            <nsc-form-field-toggle idx="content_path" controlName="path" helpTitlePopUp="Additional School Question" help="{{ content.pathHelp }}" label="{{ content.pathQuestion }}" valueNo="P" valueYes="F"
              [disabled]="formGroup.controls['path'].disabled" [group]="formGroup"></nsc-form-field-toggle>
        </div>

        <div class="col-12">
          <nsc-form-field-toggle idx="enrolledCurrently_field" controlName="enrolledCurrently"
                                 label="Are you currently enrolled at {{ content.schoolName }}?"
                                 [disabled]="formGroup.controls['enrolledCurrently'].disabled"
                                 [group]="formGroup">
          </nsc-form-field-toggle>
        </div>

      

        <div class="col-12" *ngIf="show.enrolledBeforeQuestion">
          <nsc-form-field-toggle idx="enrolledBefore_field" controlName="enrolledBefore" label="{{ content.enrolledBeforeQuestion }}" [disabled]="formGroup.controls['enrolledBefore'].disabled"
            [group]="formGroup"></nsc-form-field-toggle>
          <div class="mat-caption"><strong>Note:</strong> Electronic delivery is not available if you attended prior to the
            date above.</div>

          <nsc-spacer size="sm"></nsc-spacer>
        </div>

        <div class="col-12" *ngIf="formGroup.controls['enrolledCurrently'].value === values.no">
          <div class="row">
            <div class="col-12 col-md-4">
              <nsc-form-field-input idx="enrolledYearFrom_field" controlName="enrolledYearFrom" hint="YYYY" maxlength="4" placeholder="Year From" [group]="formGroup"
                [mask]="masks.year"></nsc-form-field-input>
            </div>
            <div class="col-12 col-md-4">
              <nsc-form-field-input idx="enrolledYearTo_field" controlName="enrolledYearTo" hint="YYYY" maxlength="4" placeholder="Year To" [group]="formGroup" [mask]="masks.year"></nsc-form-field-input>
            </div>
          </div>
        </div>

        <div class="col-12"  *ngIf="formGroup.controls['enrolledCurrently'].value === values.no && (show.zeroFeePrgmId !== null && show.zeroFeePrgmId >  0 )">
          <nsc-form-field-toggle idx="zeroFeePrgm_CurrEnroll" controlName="zeroFeePrgmCurrEnroll" label="Are you currently enrolled at a Tennessee 2 or 4 year college or university?" [disabled]="formGroup.controls['zeroFeePrgmCurrEnroll'].disabled" [group]="formGroup">
          </nsc-form-field-toggle>
        </div>

        

        <div class="col-12 col-md-4 col-lg-12 mat-body-2" *ngIf="show.campusValues">
            <p>What campus are you requesting a transcript from?</p>
            <nsc-spacer size="xsm"></nsc-spacer> 
        </div>
       
        <div class="col-12 col-md-4 col-lg-4" *ngIf="show.campusValues">
              <nsc-form-field-select-optgroup
                idx="identification_state"
                controlName="state"
                placeholder="{{ content.labelState }}"
                [group]="formGroup"
                [selectObjects]="values.states$.value"></nsc-form-field-select-optgroup>
        </div>
            
        <div class="col-12 col-md-4 col-lg-7" *ngIf="show.campusValues">
              <nsc-form-field-select
                idx="identification_campus"
                controlName="campus"
                placeholder="Campus"
                [group]="formGroup"
                [selectObjects]="values.campusValues"></nsc-form-field-select>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <div *ngIf="show.deliveryUnavailable" >
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-card [error]="true" tabindex="0">
      <nsc-card-title-error title="Invalid Delivery Methods" icon="warning">
        <div >
          Your school cannot produce this transcript in an electronic format. Please contact your school's registrar office for assistance.
        </div>
      </nsc-card-title-error>
    
    </nsc-card>
  </div>

 
  <div *ngIf="show.verifyStudentFail"   role="alert" [attr.aria-labelledby]="studentNotFound" aria-live="assertive">
    <nsc-spacer size="md"></nsc-spacer>
    
    <nsc-card [error]="true" >
      <nsc-card-title-error title="Your School Was Unable to Find Your Record" icon="warning"></nsc-card-title-error>
      <nsc-card-content>
        <div  id="studentNotFound"> 
        <p>  We are having trouble locating your student records with the personal information provided. Your request may require further
          research by your school with the information entered above. Please reconfirm the information entered.</p>
          <nsc-form-field-toggle #updateInfo idx="studentnotfound" controlName="proceedWithStudentNotFound" label="Do you want to edit the personal information you entered above?"
            [disabled]="formGroup.controls['proceedWithStudentNotFound'].disabled" [group]="formGroup"></nsc-form-field-toggle>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <div *ngIf="show.verifyStudentWithHolds" role="alert" [attr.aria-labelledby]="studentWithHolds" aria-live="assertive">
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-card [error]="true">
      <nsc-card-title-error title ="Hold(s) Identified" icon="warning"></nsc-card-title-error>
      <nsc-card-content>
        <div  id="studentWithHolds">
          <p *ngFor="let hold of content.holds" >
            <strong>{{ hold.codeDescription }}</strong><br /> {{ hold.displayText }}
          </p>
          <nsc-form-field-toggle #updateInfo idx="proceed_withholds" controlName="proceedWithHolds" label="Do you understand your hold(s) must be cleared before your transcript order will be processed?"
            note="Acceptance is required." *ngIf="!formGroup.controls['proceedWithHolds'].disabled" [group]="formGroup"></nsc-form-field-toggle>
          <div *ngIf="formGroup.controls['proceedWithHolds'].disabled">
            <hr class="nsc-hr" style="margin-bottom: 25px;"> Please cancel your order
          </div>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button mat-stroked-button id="identification_cancel" class="nsc-form-actions__button button-big" color="primary" type="button" (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button mat-raised-button  id="identification_continue" class="nsc-form-actions__button  nsc-btn__right button-big" color="primary" type="button" [disabled]="isContinueDisabled()"
      (click)="continue()">Continue <mat-icon aria-hidden="true" *ngIf="formGroup.valid">chevron_right</mat-icon></button>
  </div>
</form>
