import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CommonValues } from '../../classes/common-values';
import { CountriesService } from '../../services/countries/countries.service';
import { DataService } from '../../services/data/data.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { RecipientTypesService } from '../../services/recipient-types/recipient-types.service';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { StatesService } from '../../services/states/states.service';
import { takeUntil } from 'rxjs/operators';
import { States } from '../../interfaces/states.interface';
import { Subject } from 'rxjs';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';

@Component({
  selector: 'nsc-school-welcome',
  templateUrl: './school-welcome.component.html',
  styleUrls: ['./school-welcome.component.scss']
})
export class SchoolWelcomeComponent implements OnInit {
  window: any = window; // so we can unescape the content in the HTML template;
  trackyourOrderURL: string;
  trackYourOrderContextRoot: null;
  unsubscribe$ = new Subject();
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  content = {
    encodeWelcomeMessage: this.dataService.getNote('34'),
    welcomeMessage: null
  };

  // most of these services aren't in use on this route;
  // but they are being loaded anyway to cache the data so the user doesn't have to wait later;
  // the user will be on this page reading, that gives the Ajax calls time to resolve;
  constructor(
    private loadingIndicator: BlockingIndicatorService,
    private statesService: StatesService,
    private commonValues: CommonValues,
    private countriesService: CountriesService,
    private dataService: DataService,
    private organizationsService: OrganizationsService,
    private recipientTypesService: RecipientTypesService,
    private router: Router,
    private location: PlatformLocation,
    public dialog: MatDialog
  ) {
    location.onPopState((e) => {
      this.routeBrowserBackButton();
    });
  }


  ngOnInit() {
    // retrieve the notes from school profile
    // if shool doesn't have a profile value is coming as null so no need to unencode it
    if (this.content.encodeWelcomeMessage) {
      let shoolMessage = this.window.unescape(this.content.encodeWelcomeMessage);
      // If notes are present then we need to remove the font and sanitize the document in order to reader the style been reader
      if (shoolMessage) {
        shoolMessage = shoolMessage.replace(/font-family.+?;/g, '');
        this.content.welcomeMessage = shoolMessage;
      }
    }
    // reset all but school profile data;
    // user may have gotten to this route by hitting the back button;
    // if so, we need to clear out any user entered data;
    this.dataService.resetUserData();
    // this.trackyourOrderURL = this.dataService.get().propertyConfig.rootURL + this.commonValues.applicationURL.trackOrderContextRoot;
  }

  continue(): void {
    this.loadingIndicator.open();
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      setTimeout(() => {
        this.router.navigate([this.commonValues.routes.requestorIdentification]);
      }, 3000);
    });
  }

  // Back Button Logic to route it to specific source Entry Points - SSS / School Welcome Direct URL / TO Bridge Page
  routeBrowserBackButton(): void {
    this.dataService.data.sssRequestCheck = false;
    const path = window.location.pathname;
    if (path.indexOf(this.commonValues.routes.schoolRedirectficeCode) !== -1 || path.indexOf(this.commonValues.routes.schoolRedirectprofileid) !== -1) {
      setTimeout(() => {
        this.router.navigate([this.commonValues.routes.schoolSelect], { replaceUrl: true });
      }, 0);
    }
  }

  openConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ariaLabelledBy: "modalTitle",
      ariaDescribedBy: "modalContent",
      autoFocus:false,
      data:{
        body: 'You are leaving the ordering site. Do you want to continue?',
        title: "Proceed to Track Your Order",
        id: "modal"
      }
    });
   
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.routeToTrackOrder();
      }
      this.dialogRef = null;
    });
  }

  routeToTrackOrder(): void {
    this.dataService.routeToTrackOrderUrl();
  }

  redirectToProcessFlowLink(){
    const processFlowURL = this.dataService.data.propertyConfig.processFlowURL;
   window.open(processFlowURL, '_blank');
    }

}
