<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Sign Consent Form</nsc-card-title>
    <nsc-card-content>
      <div >
        <p>
          A signed consent form is required to release your transcript. If we do not receive your consent form within 30 calendar days from the date you submit your request, your order will be canceled, and you will not be charged.
        </p>

        <hr class="nsc-hr" style="margin-top:5px; margin-bottom:5px">

        <p>
          <strong>Requestor:</strong> {{ content.requestor }}<br>
          <strong>Order Number:</strong> {{ content.orderId }}
        </p>

        <div >
          <strong>Transcript Recipient(s)</strong>
          <div  *ngFor="let recipient of content.recipients">
            {{ recipient.organization }}
          </div>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <nsc-card *ngIf="show.electronicConsentForm">
    <nsc-card-title>Electronic Consent Form</nsc-card-title>
    <nsc-card-content>
      <div >
        <p>
          The fastest way to submit your consent form is electronically. Use your finger or mouse to draw your signature in the box. After you submit your signature, you may download a signed copy of the consent form for your records prior to providing payment in the next step.
        </p>

        <hr class="nsc-hr">

        <p class="nsc-align"> 
           Need Help Signing? 
           <button mat-icon-button aria-label="Signature help" class=""
          (click)="openDialogue()">
             <mat-icon class="nsc-icon_info_style">info</mat-icon>
          </button> 
        </p>

        <nsc-spacer size="xs"></nsc-spacer>

        <p class="mat-caption" [class.mat-error]="show.signatureError" aria-live="polite">
          <span tabindex="0" (keydown.Tab)="viewSignatureOption('legal')" >Sign Here</span>

          <span *ngIf="show.signatureError" role="status">
             Please ensure that the following standards for your signature are met: full legal name signed, no symbols used in place of name or characters are used.
          </span>
        </p>

        <div class="row">
          <div class="col-12 col-md-10 col-lg-8" [hidden]="!show.electronicSignature" attr.aria-hidden="{{!show.electronicSignature}}">
            <div id="signature" class="mat-elevation-z6"  autofocus (mouseenter)="hideWaterMark()" (mouseout)="isSignature()" (touchstart)="hideWaterMark()" (touchend)="isSignature()">
              <span class="nsc-watermark" *ngIf="isWaterMark">Sign Here</span>
              </div>
          </div>
     

          <div class="col-12" *ngIf="!show.electronicSignature" aria-label="This is an alternate method to fulfill the signature requirement.">
            <mat-checkbox group="formGroup" [disabled]="values.disableSignElectronically" controlName="signElectronically" (change)="viewSignatureOption('electronic')" class="signElectronically">If you are able to sign electronically, please check here to return to the electronic signature option.</mat-checkbox>
            <nsc-spacer size="sm"></nsc-spacer>

            <fieldset>
              <legend>
                If you need an alternate option to fulfill the signature requirement, please enter your legal first and last name below to complete the signature requirement. If you prefer, you can proceed to the Printable Consent Form section below and download and return a paper copy of the paper consent form.
              </legend>
              <nsc-spacer size="xs"></nsc-spacer>
              <nsc-form-field-confirm [allowSpace]="true" idx="legal_signature_name_field" controlNameConfirm="legalSignatureNameConfirm" controlNamePrimary="legalSignatureName"
                placeholder="Legal Signature Name" [maxlength]="60" [group]="formGroup"  [requiredConfirm]="require.legalSignatureNameConfirm"
                [requiredPrimary]="require.legalSignatureName"></nsc-form-field-confirm>
            </fieldset>
          </div>
          <div class="col-12">
          <p class="nsc-margin-top">
            <span>Signature Date:</span> {{ values.signatureDate }}
          </p>
            </div>
      </div>

      </div>

      <hr class="nsc-hr">
      <nsc-spacer size="sm"></nsc-spacer>
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
          <div class="mat-caption" style="text-align: center;">
            By submitting this signature, I, <strong>{{ content.requestor }}</strong>, certify that I am the above-named student and my electronic signature provided on this form is authentic and has the same validity and legally binding effect as signing this consent form by my hand in ink.
          </div>
        </div>
      </div>

      <nsc-spacer size="sm"></nsc-spacer>
      <div class="nsc-form-actions">
        <button
          mat-raised-button
          color="primary"
          id="orderconsent_clearsignature"
          class="nsc-button nsc-form-actions__button"
          type="button"
          (click)="clearSignature()"
          [disabled]="values.disableClearButton"  >Clear Signature</button>
        <nsc-spacer horizontal="true" size="sm"></nsc-spacer>
        <button
          mat-raised-button
          color="primary"
          id="orderconsent_acceptsignature"
          class="nsc-button nsc-form-actions__button"
          type="button"
          (click)="captureSignature()"
          [disabled]="(!formGroup.valid || (formGroup.valid && values.disableAcceptButton))">Accept Signature</button>
      </div>
    </nsc-card-content>
  </nsc-card>

  <div *ngIf="show.printableDefaultConsentForm">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card>
      <nsc-card-title>Printable Consent Form</nsc-card-title>
      <nsc-card-content>
        <div>
         <p *ngIf="values.totalFees && values.totalFees > 0">You must <strong>'Continue'</strong> to the payment page after downloading the consent form to complete this order. If you do not continue to payment, your order will NOT be processed.</p>

         <p>If you would prefer to provide a signed paper copy, you may <a href="javascript:void(0)" aria-label="download a copy of the consent form (opens dialog)" (click)="captureDownload()">download a copy of the consent form</a> and either mail a copy to National Student Clearinghouse or provide a scanned copy in an e-mail attachment. Your order will be canceled if a consent form is not received within 30 days.</p> 
         <p class="nsc-note"><b>Note:</b> When downloading the consent form with an iPhone or iPad turn off your pop-up blocker first under your Safari settings.</p>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <div *ngIf="show.printablePaperOnlyConsentForm">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card>
      <nsc-card-title>Printable Consent Form</nsc-card-title>
      <nsc-card-content>
        <div >
          <a href="javascript:void(0)" (click)="captureDownload()" aria-label="download a copy of the consent form (opens dialog)">Download the consent form</a> and either mail a copy to National Student Clearinghouse or provide a scanned copy in an e-mail attachment. Your order will be canceled if a consent form is not received within 30 days.
        </div>
      </nsc-card-content>
    </nsc-card>
  </div>

  <div *ngIf="show.paperConsentError">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card [error]="true">
      <nsc-card-title-error title="Consent Is Required" icon="warning">
        <div >
          Your order cannot be processed until your signed consent is received. Please refer to the Track Your Order link in your confirmation email for further instructions to complete your consent. This order will expire in 30 days if consent is not received.
        </div>
      </nsc-card-title-error>
      <!-- <nsc-card-content>
      
      </nsc-card-content> -->
    </nsc-card>
  </div>
  
  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-stroked-button
      id="orderconsent_cancel"
      class="nsc-form-actions__button  button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="orderconsent_continue"
      class="nsc-form-actions__button nsc-btn__right button-big "
      color="primary"
      type="button"
      (click)="continue()"
      [disabled]="!values.enableContinueButton">Continue <mat-icon aria-hidden="true" *ngIf="values.enableContinueButton">chevron_right</mat-icon></button>
  </div>
</form>
 