import * as moment from 'moment';
import {
  cloneDeep as _cloneDeep,
  filter as _filter,
  remove as _remove,
  sortBy as _sortBy,
  get as _get,
} from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiResponseFees } from '../../interfaces/api-response-fees.interface';
import { CommonValues } from '../../classes/common-values';
import { ConvertDeliveryMethod } from '../../classes/convert-delivery-method';
import { DataService } from '../../services/data/data.service';
import { FeesService } from '../../services/fees/fees.service';
import { ManageFields } from '../../classes/manage-fields';
import {
  FutureProcessingOption,
  SchoolProfileDeliveryMethod,
  SchoolProfileProcessingOption,
  SchoolProfileQuantity,
  SchoolProfileTranscriptPurpose,
  SchoolProfileTranscriptType
} from '../../interfaces/api-response-school-profile.interface';
import { FormFieldFilesComponent } from '../../components/form-field-files/form-field-files.component';
import { SecurityService } from '../../services/security/security.service';
import { SelectOption } from '../../interfaces/select-option.interface';
import { StudentProfileDetail } from '../../interfaces/api-response-student-profile.interface';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { IfStmt } from '@angular/compiler';
import { DialogHelpComponent } from '../../components/dialog-help/dialog-help.component';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';


const SortOrderForDeliveryArray = [
  'EP',
  'ET',
  'M',
  'MU',
  'CU',
  'MC',
  'MI',
  'X',
  'XU',
  'XC',
  'XI',
  'FU',
  'FC',
  'FI',
  'O',
  'H',
];

const SortOrderForRushDelivery = [
  'REP',
  'RET',
  'RM',
  'RMU',
  'RCU',
  'RMC',
  'RMI',
  'RX',
  'RXU',
  'RXC',
  'RXI',
  'RFU',
  'RFC',
  'RFI',
  'RO',
  'RH',
];

@Component({
  selector: 'nsc-recipient-delivery-method',
  templateUrl: './recipient-delivery-method.component.html',
  styleUrls: ['./recipient-delivery-method.component.scss'],
  providers: [CurrencyPipe]
})
export class RecipientDeliveryMethodComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(FormFieldFilesComponent)
  private formFieldFiles: FormFieldFilesComponent;

  data = this.dataService.get();
  formGroup: FormGroup;
  hasN = false;
  schoolProfile = this.data.schoolProfile;
  unsubscribe$ = new Subject();
  unsubscribeFee$ = new Subject();
  // for TN $0 fee needs to display the fees in the UI
  isTNSchool = false;
  isSPDESchool = false;
  isFASTSchool = false;
  showIndexConsent = false;
  content = {
    allowedFileTypes: [],
    allowUploads: this.schoolProfile.allowDocUpload === this.commonValues.api.yes,
    attachmentCount: this.schoolProfile.docUploadLimit ? this.schoolProfile.docUploadLimit : null,
    deliveryMethodHelpText: null,
    deliveryMethodDisplay: null,
    deliveryMethodType: null,
    helpText: null,
    // changes based on answer to "when do you want to have your transcript(s) sent" question;
    requireDegree: false,
    requireTerm: false,
    requireYear: false
  };

  masks = {
    year: "0000"
  };

  show = {
    additionalWhenQuestions: false,
    labelForG: false,
    labelForD: false,
    processingMethods: false,
    transcriptPurposes: this.schoolProfile.askXcriptPurpose,
    transcriptTypes: this.schoolProfile.askXcriptType,
    docUploadSection: true,
    futureProcessingOption: false,
    allowDocWaived: (this.schoolProfile.allowDocWaived === this.commonValues.api.yes && this.data.form.recipient.delivery.allowDocWaived),
  };

  values = {
    deliveryMethods: [],
    deliveryMethodsNonEtxG: [],
    deliveryMethodsNonEtxR: [],
    deliveryMethodsEtxG: [],
    deliveryMethodsEtxR: [],
    fees: null,
    howMany: null,
    howManyAll: null,
    howManyElectronic: null,
    options: this.dataService.getFeePathType(),
    attachmentUrl: '/api/tsorder/attachment/' + this.dataService.get().response.orderHeader.toOrderId,
    processingMethods: [
      {
        name: 'Standard Processing',
        value: 'G'
      },
      {
        name: 'Rush Processing',
        value: 'R'
      }
    ],
    programs: [],
    totalFees: 0, // value to be emitted from `fee-summary` component;
    transcriptWhen: null,
    transcriptTypes: null,
    transcriptPurposes: null,
    ungradedTerms: [],
    yes: this.commonValues.api.yes, // used in `continue` button logic (in template);
    yearValidationOptional: null, // set after year calculation is done in initForm();
    yearValidationRequired: null,
    isEnrolledBefore: this.dataService.isEnrolledBefore(),
    allowDocWaivedConfigured: this.schoolProfile.allowDocWaived === this.commonValues.api.yes,
    fpOptionTerms: [],
    fpOptionYears: [],
  };

  constructor(
    private commonValues: CommonValues,
    private convertDeliveryMethod: ConvertDeliveryMethod,
    private currencyPipe: CurrencyPipe,
    private dataService: DataService,
    private feesService: FeesService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private router: Router,
    private securityService: SecurityService,
    private timeoutService: TimeoutService,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private blockingIndicatorService: BlockingIndicatorService,
  ) { }

  ngOnInit() {
    this.initDefaultFpOptionsYears();
    this.initAllowedFileTypes();
    this.initUngradedTermValues();
    this.initProgramValues();
    this.initTranscriptWhenValues();
    this.initDeliveryMethodValues();
    this.initTranscriptTypeValues();
    this.initTranscriptPurposeValues();
    this.initHowManyValues();
    this.initForm();
    this.setDeliveryMethodContent();
    this.initProcessingOptionsListener();
    this.initWhenListener();
    this.initCopiesValue();
    this.initTermsValue();
    this.getFees();
    this.initFeesListener();
    this.setHowManyDefaultValue();
    this.checkFileFormatForExchangeNetwork();
    this.setShowConsentIndexFile();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeFee$.next();
    this.unsubscribeFee$.complete();
  }

  ngAfterViewInit() {
    if (this.schoolProfile.allowDocUpload === this.commonValues.api.yes) {
      this.initFileAttachments();
    }
  }

  initDefaultFpOptionsYears(): void {
    const actualFullDate = moment();
    let yearsToDisplay = 2;
    let currentYear = actualFullDate.year();
    // return the months in numbers from 0 to 11 , therefore march is 2
    if (actualFullDate.month() < 2) {
      yearsToDisplay = 3;
      currentYear = currentYear - 1;
    }
    for (let i = 0; i < yearsToDisplay; i++) {
      const queryObj = { value: currentYear.toString(), name: currentYear };
      this.values.fpOptionYears.push(queryObj);
      currentYear += 1;
    }
    this.setDefaultFpOptionsTerms();
  }

  setDefaultFpOptionsTerms(): void {
    this.values.fpOptionTerms = [];
    this.schoolProfile.futureProcessingOptions.forEach((option: FutureProcessingOption) => {
      this.values.fpOptionTerms.push({
        name: option.label,
        value: option.code
      });
    });
    this.values.fpOptionTerms = _sortBy(this.values.fpOptionTerms, 'name');
  }

  initFileAttachments(): void {
    if (typeof this.formFieldFiles !== 'undefined' && this.formFieldFiles != null && typeof this.formFieldFiles.uploadedFiles !== 'undefined' && this.formFieldFiles.uploadedFiles != null) {
      this.formFieldFiles.uploadedFiles = (this.data.form.recipient.attachments) ? this.data.form.recipient.attachments : [];
      this.formFieldFiles.updateDataSource();
      const controls = this.formGroup.controls;

      if (this.values.allowDocWaivedConfigured) {
        this.formFieldFiles.filesUploadedCount$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(items => {
            if (items === 0) {
              controls.allowDocWaived.clearValidators();
              this.manageFields.disable([controls.allowDocWaived]);
              this.show.allowDocWaived = false;
            } else {
              if (!this.show.allowDocWaived) {
                this.show.allowDocWaived = true;
                controls.allowDocWaived.setValidators([Validators.required]);
                this.formGroup.patchValue({
                  allowDocWaived: null
                });
                this.manageFields.enable([controls.allowDocWaived]);
              }
            }
          });
      }
    }
  }

  initAllowedFileTypes(): void {
    // break up the formats into an array and parse out any invalid formats;
    if (this.schoolProfile.docUploadFormats) {
      const schoolFormats = this.schoolProfile.docUploadFormats.toLowerCase().split('|');
      const allowedExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tif', 'tiff', 'pdf', 'doc', 'docx'];

      schoolFormats.forEach(extension => {
        if (allowedExtensions.indexOf(extension) > -1) {
          this.content.allowedFileTypes.push(extension);
        }
      });
    }
  }

  initTranscriptWhenValues(): void {
    // convert `processingOptions` to a usable array for a SELECT;
    this.values.transcriptWhen = this.schoolProfile.processingOptions.map(
      (option: SchoolProfileProcessingOption) => {
        return {
          name: option.description,
          value: option.code.toUpperCase()
        };
      }
    );

    // alphabetize the array;
    this.values.transcriptWhen = _sortBy(this.values.transcriptWhen, 'name');

    // find out if the available processing options have "current transcript" (identified by the code property);
    let indexN;
    this.values.transcriptWhen.map((option: SelectOption, index: number) => {
      if (option.value === this.commonValues.api.now) {
        indexN = index;
        // this.hasN = true;
      }
    });

    // move the "current transcript" value to the top;
    // if it's already the first index (0) do nothing;
    if (indexN) {
      this.values.transcriptWhen.splice(0, 0, this.values.transcriptWhen.splice(indexN, 1)[0]);
    }

    // if the user wasn't verified for an Ellucian enabled school, and "current transcript" is available;
    // then only show the "current transcript" option to the user;
    if (this.dataService.isSchoolEllucian() && this.dataService.isStudentVerified() === false) {
      _remove(
        this.values.transcriptWhen,
        o => o.value !== this.commonValues.api.now
      );
    }

    // remove options for verified students if there are no options to display;
    if (this.dataService.isStudentVerified()) {
      
      let removeTerms: boolean = false;
      let removePrograms: boolean = false;
 
      // remove the `After Grades are Posted` option if no ungradedTerms are defined or activeTermExists value is N;
      if(this.values.ungradedTerms.length === 0 || this.data.studentProfile.activeTermExists === this.commonValues.api.no){
        removeTerms = true;
      }
      //not removing the `After Grades are Posted` option if futureTerm value is Y even though ungradedTerms donot exsit;
      if(this.data.studentProfile.futureTerm === this.commonValues.api.yes){
        removeTerms = false;
      }
     
      if (removeTerms){
        _remove(
          this.values.transcriptWhen,
          o => o.value === this.commonValues.api.afterGradesArePosted
        );
      }
      // remove the `After Degree is Awarded` option if no programs are defined or activeProgramExists value is N ;
      if (this.values.programs.length === 0 || this.data.studentProfile.activeProgramExists === this.commonValues.api.no){
        removePrograms = true;
      }
      //not removing the `After Degree is Awarded` option if futureProgram value is Y even though programs donot exsit;
      if(this.data.studentProfile.futureProgram === this.commonValues.api.yes){
        removePrograms = false;
      }

      if (removePrograms) {
        _remove(
          this.values.transcriptWhen,
          o => o.value === this.commonValues.api.afterDegreeIsAwarded
        );
      }
    }
  }


  initUngradedTermValues(): void {
    this.values.ungradedTerms = this.dataService
      .getStudentTerms()
      .map((option: StudentProfileDetail) => {
        return {
          name: option.codeDescription,
          value: option.code.toUpperCase()
        };
      });
  }

  initProgramValues(): void {
    this.values.programs = this.dataService
      .getStudentPrograms()
      .map((option: StudentProfileDetail) => {
        return {
          name: option.codeDescription,
          value: option.code.toUpperCase()
        };
      });
  }

  initDeliveryMethodValues(): void {
    // convert `deliveryMethods` to a usable array for a SELECT;
    // need to "fix" the electronic display because the back-end is serving up content we dont want the user to see;

    // split up the delivery methods into two groups, general and rush;
    // but only if there are delivery methods for those groups;
    // be default assume neither group are available to display;
    let hasG = false;
    let hasR = false;

    let indexToPushG = 0;
    let indexToPushR = 0;

    this.values.options.deliveryMethods.map((option: SchoolProfileDeliveryMethod) => {
      const display = this.convertDeliveryMethod.get(option.displayWithFee);
      const xmitCode = option.xmitCode;
      const isOptionETX = option.deliveryMethodType === this.commonValues.api.etx;
      const isCurrentlyEnrolled =
        this.data.response.student.currEnrolled === this.commonValues.api.yes;
      const isDeliveryMethodVisible = () => {

        if (this.data.form.recipient.who.sendElectronically === this.commonValues.api.yes) {
          // User answered yes
          if (this.data.form.recipient.who.matchedUboxEntry) {
            // and there is a domainEmail match, only show electronic exchange as delivery method;
            return option.deliveryMethodType === this.commonValues.api.etx ? true : false;
          }
          else {
            // User answered yes and there is no domainEmail match, default to ePDF;
            return option.deliveryMethodType === this.commonValues.api.electronic ? true : false;
          }
        }
        else if (this.data.form.recipient.who.sendElectronically === this.commonValues.api.no || this.values.isEnrolledBefore) {
          // Hide ePDF and ETX when: User doesn't want to send eletronically OR he was enrolledBefore.
          return option.deliveryMethodType === this.commonValues.api.etx ||
            option.deliveryMethodType === this.commonValues.api.electronic ? false : true;
        }
        else {
          // if the target is ETX, then only show ETX options to the user, if they answered the `enrolledBefore` question properly;
          // if the target is NOT ETX, then only show the non-ETX options to the user;
          return this.isElectronicExchange() ? isOptionETX && !this.values.isEnrolledBefore : !isOptionETX;
        }

      };
      const pushDeliveryMethod = (array, name, value, sortNum, xpathCode): void => {
        array.push({
          name: name,
          value: value,
          sortNum,
          xpathCode
        });
      };

      // determine which array to populate with this otpion;
      const deliveryMethodsG =
        this.isElectronicExchange() && isOptionETX
          ? this.values.deliveryMethodsEtxG
          : this.values.deliveryMethodsNonEtxG;
      const deliveryMethodsR =
        this.isElectronicExchange() && isOptionETX
          ? this.values.deliveryMethodsEtxR
          : this.values.deliveryMethodsNonEtxR;

      // if the user attended school prior to electronic delivery being available, dont show the option to the user;
      if (isDeliveryMethodVisible()) {
        switch (option.deliveryMethodCategory) {
          case this.values.processingMethods[0].value:
            hasG = true;
            indexToPushG = indexToPushG ? indexToPushG : SortOrderForDeliveryArray.length;
            let indexG = SortOrderForDeliveryArray.includes(xmitCode) ? SortOrderForDeliveryArray.indexOf(xmitCode) : indexToPushG++;
            pushDeliveryMethod(deliveryMethodsG, display, option.deliveryMethodId, indexG, option.xmitCode);
            break;

          case this.values.processingMethods[1].value:
            hasR = true;
            indexToPushR = indexToPushR ? indexToPushR : SortOrderForRushDelivery.length;
            let indexR = SortOrderForRushDelivery.includes(xmitCode) ? SortOrderForRushDelivery.indexOf(xmitCode) : indexToPushR++;
            pushDeliveryMethod(deliveryMethodsR, display, option.deliveryMethodId, indexR, option.xmitCode);
            break;
        }
      }
    });


    // sort the non-ETX delivery methods;
    // dont need to sort the ETX delivery methods, there should be only one;
    this.values.deliveryMethodsNonEtxG = _sortBy(this.values.deliveryMethodsNonEtxG, 'sortNum');
    this.values.deliveryMethodsNonEtxR = _sortBy(this.values.deliveryMethodsNonEtxR, 'sortNum');
    if (hasG && hasR) {
      this.show.processingMethods = true;
    }
  }

  initTranscriptTypeValues(): void {
    // convert `transcriptTypes` to a usable array for a SELECT;
    this.values.transcriptTypes = this.values.options.transcriptTypes.map(
      (option: SchoolProfileTranscriptType) => {
        return {
          name: option.displayWithFee,
          value: option.xcriptTypeId
        };
      }
    );

    // alphabetize the array;
    this.values.transcriptTypes = _sortBy(this.values.transcriptTypes, 'name');

    // hide the question if there are no values to display;
    if (this.values.transcriptTypes.length === 0) {
      this.show.transcriptTypes = false;
    }
  }

  initTranscriptPurposeValues(): void {
    // convert `transcriptPurposes` to a usable array for a SELECT;
    this.values.transcriptPurposes = this.schoolProfile.transcriptPurposes.map(
      (option: SchoolProfileTranscriptPurpose) => {
        return {
          name: option.display,
          value: option.xcriptPurposeId
        };
      }
    );

    // alphabetize the array;
    this.values.transcriptPurposes = _sortBy(this.values.transcriptPurposes, 'name');

    // hide the question if there are no values to display;
    if (this.values.transcriptPurposes.length === 0) {
      this.show.transcriptPurposes = false;
    }
  }

  initHowManyValues(): void {
    // convert `quantities` to a usable array for a SELECT;
    // but the options to display are based on the delivery method;
    // if electronic - only show one (1) - can't order 15 PDFs, but you can order 15 paper transcripts;
    // so we parse the values into two arrays;
    // and display the appropriate array based on the selected delivery method;
    this.values.howManyAll = this.values.options.quantities.map((option: SchoolProfileQuantity) => {
      return {
        name: option.displayWithFee,
        value: option.quantityId
      };
    });

    // sort the values by the number;
    // the expected format is XX copies = $Y.ZZ;
    // we want to sort on the numeric value of XX - so we end up with 1, 2, 3, instead of 1, 10, 11, 2;
    this.values.howManyAll = _sortBy(this.values.howManyAll, option =>
      Number(option.name.split(' ')[0])
    );

    // electronic delivery method only allows one copy - the first in the list;
    // assume 1 copy is always defined;
    this.values.howManyElectronic = [this.values.howManyAll[0]];

    // now that the values are organized into two arrays, lets determine which array to init the form with;
    // init the "how many copies" field based off the selected "delivery method";
    const selectedDeliveryMethod = this.dataService.getDeliveryMethodObject(
      this.data.form.recipient.delivery.deliveryMethod
    );

    // if a value was found, and it's electronic, show only the electronic values for the howMany question;
    if (selectedDeliveryMethod) {
      if (
        this.commonValues.electronicDeliveryTypes.indexOf(
          selectedDeliveryMethod.deliveryMethodType
        ) > -1
      ) {
        this.values.howMany = this.values.howManyElectronic;
      } else {
        this.values.howMany = this.values.howManyAll;
      }
    } else {
      this.values.howMany = this.values.howManyAll;
    }
  }

  initForm(): void {
    const currentYear = moment().year();
    const maxYear = currentYear + 10;
    const minYear = currentYear - 1;
    const delivery = this.data.form.recipient.delivery;


    this.values.yearValidationOptional = [
      Validators.minLength(4),
      Validators.max(maxYear),
      Validators.min(minYear)
    ];
    this.values.yearValidationRequired = [
      Validators.minLength(4),
      Validators.max(maxYear),
      Validators.min(minYear),
      Validators.required
    ];

    const specialInstructionsValue =
      this.data.schoolProfile.askSpecialInstr === this.commonValues.api.optional
        ? delivery.specialInstructions
        : this.commonValues.nullDisabled;

    this.formGroup = this.formBuilder.group({
      transcriptWhen: new FormControl(
        delivery.transcriptWhen
          ? delivery.transcriptWhen
          : this.hasN
            ? this.commonValues.api.now
            : null,
        [Validators.required]
      ),
      transcriptType: new FormControl(
        this.show.transcriptTypes ? delivery.transcriptType : this.commonValues.nullDisabled,
        [Validators.required]
      ),
      transcriptPurpose: new FormControl(
        this.show.transcriptPurposes ? delivery.transcriptPurpose : this.commonValues.nullDisabled,
        [Validators.required]
      ),
      processingMethod: new FormControl(
        this.show.processingMethods
          ? delivery.processingMethod
          : this.commonValues.nullDisabled,
        [Validators.required]
      ),
      deliveryMethod: new FormControl(this.data.form.recipient.delivery.deliveryMethod, [
        Validators.required
      ]),
      acceptTerms: new FormControl(delivery.acceptTerms, [Validators.required]),
      consentShareInfo: new FormControl(
        this.getShowConsentIndexFile()
          ? delivery.consentShareInfo
          : this.commonValues.nullDisabled,
        [Validators.required]
      ),
      howMany: new FormControl(delivery.howMany || this.values.howMany[0].value, [
        Validators.required
      ]),
      course1: new FormControl(delivery.course1 || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      course2: new FormControl(delivery.course2 || this.commonValues.nullDisabled),
      term: new FormControl(delivery.term || this.commonValues.nullDisabled),
      zeroFeeProgramEligible: new FormControl(delivery.zeroFeeProgramEligible),
      year: new FormControl(
        delivery.year || this.commonValues.nullDisabled,
        this.values.yearValidationOptional
      ),
      degreeTitle: new FormControl(delivery.degreeTitle || this.commonValues.nullDisabled),
      specialInstructions: new FormControl(specialInstructionsValue),
      ungradedTerm: new FormControl(delivery.ungradedTerm || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      program: new FormControl(delivery.program || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      allowDocWaived: new FormControl(delivery.allowDocWaived || this.commonValues.nullDisabled),
    });

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });

    // now that the form is set, we can set the delivery methods;
    // couldn't do this during the initValues method because we needed the form control;
    this.setDeliveryMethods();
    if(this.values.transcriptWhen && this.values.transcriptWhen.length === 1){
    this.formGroup.controls["transcriptWhen"].setValue(this.values.transcriptWhen[0].value);
    }
  }

  // the processing options isn't always visible;
  // but when it is, it controls the visibility and content of the delivery method question;
  initProcessingOptionsListener(): void {
    this.formGroup.controls.processingMethod.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.setDeliveryMethods();
        }
      });
  }

  initWhenListener(): void {
    // hide/show additional questions based on the transcriptWhen;
    this.formGroup.controls.transcriptWhen.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.showAdditionalTranscriptWhenQuestions(value);
        }
      });

    // run onLoad, and pass the onChange prop as false to the values don't get erased when the fields are shown;
    this.showAdditionalTranscriptWhenQuestions(this.formGroup.controls.transcriptWhen.value, false);
  }

  initCopiesValue(): void {
    // if a user has selected `electronic pdf` then prevent the user from selecting more than one copy;
    // NSC isn't going to send an email with 5 PDF attachements that are all the same;
    this.formGroup.controls.deliveryMethod.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        // if a selection has been made, we can now show and manipulate the rest of the form;
        if (value) {
          this.formGroup.patchValue({
            howMany: null,
          });

          this.setDeliveryMethodContent();
        }
      });
  }

  initTermsValue(): void {
    // when the user changes the delivery method, reset the terms question back to no;
    // even if they previously selected yes, they selected yes for a different question/delivery method;
    this.formGroup.controls.deliveryMethod.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.formGroup.patchValue({
            acceptTerms: null
          });
        }
      });
  }

  initFeesListener(): void {
    this.formGroup.controls.deliveryMethod.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.getFees();
      });
    this.formGroup.controls.howMany.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.getFees();
      });
    this.formGroup.controls.transcriptType.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.getFees();
      });
  }

  setTotalFees(totalFees: number): void {
    // total fees are calculated within the fee-summary component and emited;
    // we need to record the total value here to make calculation on the order-review page easier;
    this.values.totalFees = totalFees;
  }

  showAdditionalTranscriptWhenQuestions(value, onChange = true): void {
    this.show.futureProcessingOption = this.checkDisplayFpOption(value);
    // text popup message needs to be displayed dinamically for any deliveryProcessionOption
    const transcriptWhenObject = this.dataService.getProcessingOptionObject(value);
    // if the value message has content then show the info icon otherwise hide it
    this.content.helpText = (transcriptWhenObject && transcriptWhenObject.helpText && transcriptWhenObject.helpText.length > 0) ? transcriptWhenObject.helpText : null;
    const controls = this.formGroup.controls;

    this.show.labelForG = false;
    this.show.labelForD = false;

    controls.degreeTitle.disable();
    controls.course1.disable();
    controls.course2.disable();
    controls.program.disable();
    controls.term.disable();
    controls.ungradedTerm.disable();
    controls.year.disable();

    // reset the validation for the following fields;
    this.content.requireDegree = false;
    this.content.requireTerm = false;
    this.content.requireYear = false;
    controls.degreeTitle.clearValidators();
    controls.term.clearValidators();
    controls.year.setValidators(this.values.yearValidationOptional);

    // always clear out the fields we will hide/show;
    // but only on change, not on init (otherwise their value would be lost on edit);
    if (onChange) {
      this.formGroup.patchValue({
        course1: null,
        course2: null,
        degreeTitle: null,
        program: null,
        term: null,
        ungradedTerm: null,
        year: null
      });
    }

    if (value) {
      switch (value) {
        case this.commonValues.api.afterGradesAreChanged:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([
            controls.course1,
            controls.course2,
            controls.term,
            controls.year
          ]);
          break;

        case this.commonValues.api.afterDegreeIsAwarded:
          this.show.additionalWhenQuestions = true;

          // if the user is a verified student and has programs defined;
          // show the programs control, otherwise show the degree/term/year fields;
          if (this.data.studentProfile && (this.data.studentProfile.futureProgram === this.commonValues.api.yes)) {
            controls.program.clearValidators();
            this.manageFields.disable([controls.program]);
          } else if (this.values.programs.length) {
            this.manageFields.enable([controls.program]);

            // if only a single option exists, pre-select it;
            if (this.values.programs.length === 1) {
              this.formGroup.patchValue({
                program: this.values.programs[0].value
              });
            }
          } else {
            this.show.labelForD = true;

            this.manageFields.enable([controls.degreeTitle, controls.term, controls.year]);

            // change the validation for the following fields;
            this.content.requireDegree = true;
            this.content.requireTerm = true;
            this.content.requireYear = true;
            controls.degreeTitle.setValidators([Validators.required]);
            controls.term.setValidators([Validators.required]);
            controls.year.setValidators(this.values.yearValidationRequired);
          }

          const intergrationProvider = this.dataService.getIntegrationProvider();
          if (intergrationProvider && intergrationProvider.toUpperCase() === 'SISCLOUDAPI') {
            if (this.values.ungradedTerms.length) {
              this.show.additionalWhenQuestions = true;
              this.show.labelForG = true;
              this.manageFields.enable([controls.ungradedTerm]);

              // if only a single option exists, pre-select it;
              if (this.values.ungradedTerms.length === 1) {
                this.formGroup.patchValue({
                  ungradedTerm: this.values.ungradedTerms[0].value
                });
              }
            }
          }
          break;

        case this.commonValues.api.holdForFallTermGrades:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.term, controls.year]);
          break;

        case this.commonValues.api.afterGradesArePosted:
          if (this.dataService.isStudentVerified()) {
            // for verified students that have ungraded terms, show them;
            if (this.data.studentProfile.futureTerm === this.commonValues.api.yes) {
              controls.ungradedTerm.clearValidators();
              this.manageFields.disable([controls.ungradedTerm]);
            } else if (this.values.ungradedTerms.length) {
              this.show.additionalWhenQuestions = true;
              this.show.labelForG = true;
              this.manageFields.enable([controls.ungradedTerm]);

              // if only a single option exists, pre-select it;
              if (this.values.ungradedTerms.length === 1) {
                this.formGroup.patchValue({
                  ungradedTerm: this.values.ungradedTerms[0].value
                });
              }
            }
          } else {
            // if this is not an Ellucian enabled school then show term/year as optional;
            if (this.dataService.isSchoolEllucian() === false) {
              this.show.additionalWhenQuestions = true;
              this.show.labelForG = true;

              if (this.show.futureProcessingOption) {
                if (this.data.schoolProfile.requireTermYear === this.commonValues.api.yes) {
                  this.content.requireTerm = true;
                  this.content.requireYear = true;
                  controls.term.setValidators([Validators.required]);
                  controls.year.setValidators(this.values.yearValidationRequired);
                } else {
                  this.content.requireTerm = false;
                  this.content.requireYear = false;
                  controls.term.clearValidators();
                  controls.year.clearValidators();
                }
              }
              this.manageFields.enable([controls.term, controls.year]);
            }
          }
          break;

        case this.commonValues.api.afterHonorsArePosted:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.term, controls.year]);
          break;

        case this.commonValues.api.afterCourseIsCompleted:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.course1, controls.course2]);
          break;

        case this.commonValues.api.afterDegreeIsPosted:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.degreeTitle, controls.term, controls.year]);
          break;

        case this.commonValues.api.holdForSpringTermGrades:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.term, controls.year]);
          break;

        case this.commonValues.api.holdForCurrentTermSchedule:
          this.show.additionalWhenQuestions = true;

          this.manageFields.enable([controls.term, controls.year]);
          break;

        default:
          this.show.additionalWhenQuestions = false;
          break;
      }
    }
  }

  isElectronicExchange(): boolean {
    const who = this.data.form.recipient.who;

    // we know if this is an ETX recipient if a recipient had a response object, and the value isn't "not in list";
    // Handle also when is OBOR school and oborQuestion is 'Y', we need to pass as an Exchange electronic PDF
    return (
      who.recipientResponse !== null && who.department !== this.commonValues.select.notInList.value
      && (who.oborDeliveryEligibility !== this.commonValues.api.no || who.recipientResponse.exchangeNetworkType === this.commonValues.api.speede || who.recipientResponse.exchangeNetworkType === this.commonValues.api.fast));
  }

  checkFileFormatForExchangeNetwork(): void {
    const who = this.data.form.recipient.who;

    if (who.recipientResponse !== null && who.recipientResponse.exchangeNetworkType === this.commonValues.api.etxNetwork) {
      const department = who.department;
      if (department !== this.commonValues.select.notInList.value && this.getETXDepartmentFileFormat(department) !== null && (this.getETXDepartmentFileFormat(department).toUpperCase() === this.commonValues.api.edi || this.getETXDepartmentFileFormat(department).toUpperCase() === this.commonValues.api.xml)) {
        this.show.docUploadSection = false;
      } else if (who.sendElectronically === this.commonValues.api.yes) {
        // User answered yes
        if (who.matchedUboxEntry !== null && who.matchedUboxEntry.fileFormat.toUpperCase() === this.commonValues.api.edi || who.matchedUboxEntry.fileFormat.toUpperCase() === this.commonValues.api.xml) {
          // and there is a domainEmail match
          this.show.docUploadSection = false;

        }
      } else if (typeof who.recipientResponse.subNetwork !== 'undefined' && who.recipientResponse.subNetwork !== null && who.recipientResponse.subNetwork.subNetworkType === this.commonValues.api.amcas) {
        if (who.recipientResponse.subNetwork.fileFormat.toUpperCase() === this.commonValues.api.edi || who.recipientResponse.subNetwork.fileFormat.toUpperCase() === this.commonValues.api.xml) {
          // and there is a domainEmail match
          this.show.docUploadSection = false;
        }
      } else if (typeof who.recipientResponse.subNetwork !== 'undefined' && who.recipientResponse.subNetwork !== null && who.recipientResponse.subNetwork.subNetworkType === this.commonValues.api.lsac) {
        if (who.recipientResponse.subNetwork.fileFormat.toUpperCase() === this.commonValues.api.edi || who.recipientResponse.subNetwork.fileFormat.toUpperCase() === this.commonValues.api.xml) {
          // and there is a domainEmail match
          this.show.docUploadSection = false;
        }
      }
    } else if (who.recipientResponse !== null && who.recipientResponse.exchangeNetworkType === this.commonValues.api.obor && who.oborDeliveryEligibility === this.commonValues.api.yes || who.recipientResponse !== null && (who.recipientResponse.exchangeNetworkType === this.commonValues.api.speede || who.recipientResponse.exchangeNetworkType === this.commonValues.api.fast)) {
      if (who.recipientResponse.exchangeChanel.fileFormat.toUpperCase() === this.commonValues.api.edi || who.recipientResponse.exchangeChanel.fileFormat.toUpperCase() === this.commonValues.api.xml) {

        this.show.docUploadSection = false;
      }
    }
  }

  setShowConsentIndexFile(): void {
    this.showIndexConsent = this.getShowConsentIndexFile();
  }

  getShowConsentIndexFile(): boolean {

    let showConsentIndexFileQuestion = false;
    const who = this.data.form.recipient.who;
    const departments = _get(who, 'recipientResponse.recipientDepartments', []);
    const uboxRecipientResponse = (who.oborDeliveryEligibility !== null && who.oborDeliveryEligibility === this.commonValues.api.yes) ? null : who.matchedUboxEntry;
    let recipientResponseDepartment = null;
    if(uboxRecipientResponse && uboxRecipientResponse.deptProcessingOption === this.commonValues.api.slate){
      recipientResponseDepartment = _filter(departments, {
        ftpAccountName: uboxRecipientResponse.etxDeptId
      });
    }else{
      recipientResponseDepartment = _filter(departments, {
        deptId: who.department
      });
    }


    const senderAllowIndexFile = this.data.schoolProfile.allowIndexFile;
    const exchangeDataObject = recipientResponseDepartment.length ? recipientResponseDepartment[0] : null;
    if ((senderAllowIndexFile !== null && senderAllowIndexFile === this.commonValues.api.yes &&
      exchangeDataObject && exchangeDataObject.sendIndexFile === this.commonValues.api.yes) ||
      (exchangeDataObject && exchangeDataObject.sendIndexFile === this.commonValues.api.yes && exchangeDataObject.deptProcessingOption === this.commonValues.api.slate)) {
      showConsentIndexFileQuestion = true;
    }
    return showConsentIndexFileQuestion;

  }

  getETXDepartmentFileFormat(id): string {
    // retrieving department name from recipientResponse.
    const who = this.data.form.recipient.who;

    const departments = _get(who, 'recipientResponse.recipientDepartments', []);
    const recipientResponseDepartment = _filter(departments, {
      deptId: id
    });
    return recipientResponseDepartment.length > 0 ? _get(recipientResponseDepartment[0], 'fileFormat', null) : null;
  }
  getNextRoute(): string {
    // now move to the next route (depending on what delivery method was selected);
    // if the selected delivery method is ETX, skip the address route;
    // if the delivery method is electronic, go to the electronic address form;
    // otherwise go to the mail address form;

    let routeNext = this.commonValues.routes.recipientAddress;

    const deliveryMethodObject = this.dataService.getDeliveryMethodObject(
      this.formGroup.controls.deliveryMethod.value
    );

    switch (deliveryMethodObject.deliveryMethodType) {
      case this.commonValues.api.etx:
        if (this.data.form.recipient.who.department !== 'NOT IN LIST' ||
          (this.data.form.recipient.who.emailPrimary || this.data.form.recipient.address.emailPrimary)) {
          routeNext = this.commonValues.routes.orderReview;
        }
        else {
          routeNext = this.commonValues.routes.recipientEmail;
        }
        break;
      case this.commonValues.api.electronic:
        if (!this.data.form.recipient.who.matchedUboxEntry &&
          this.data.form.recipient.who.sendElectronically === this.commonValues.api.yes &&
          (this.data.form.recipient.who.emailPrimary || this.data.form.recipient.address.emailPrimary)) {
          // there was no match for central inbox, but the user answered 'Y' to 'Do You want to send Electronically ?'
          // at this point, we already have a recipient email, and we should skip the recipient email page and go to order review.
          routeNext = this.commonValues.routes.orderReview;
        }
        else {
          // follow normal procedure and go to recipient email route.
          routeNext = this.commonValues.routes.recipientEmail;
        }
        break;
      case this.commonValues.api.hold:
        // if it's a 'Hold for Pick Up' order we don't require additional information from the requestor, go to 'Order Review'.
        routeNext = this.commonValues.routes.orderReview;
        break;
    }

    return routeNext;
  }

  setDeliveryMethods(): void {
    // determine which delivery methods to use, standard or rush;
    const deliveryMethodsG = this.isElectronicExchange()
      ? this.values.deliveryMethodsEtxG
      : this.values.deliveryMethodsNonEtxG;
    const deliveryMethodsR = this.isElectronicExchange()
      ? this.values.deliveryMethodsEtxR
      : this.values.deliveryMethodsNonEtxR;

    // if the processing method value is set, then it's standard if it's equal to the first option;
    // otherwise use stantard if values are present, otherwise use rush;
    const processingMethodValue = this.formGroup.controls.processingMethod.value;
    const isStandardProcessing =
      processingMethodValue !== null
        ? processingMethodValue === this.values.processingMethods[0].value
        : deliveryMethodsG.length > 0;

    this.values.deliveryMethods = isStandardProcessing ? deliveryMethodsG : deliveryMethodsR;

    // if the selected value is not in the array, update the value;
    // if it is in the array, do nothing, we dont want to erase that value;
    if (
      _filter(this.values.deliveryMethods, {
        value: this.formGroup.controls.deliveryMethod.value
      }).length === 0
    ) {
      const singleDeliveryMethod = this.values.deliveryMethods.length === 1;

      // now that the delivery methods have been set, if there is only one to choose from, select it;
      this.formGroup.patchValue({
        deliveryMethod: singleDeliveryMethod ? this.values.deliveryMethods[0].value : null
      });

      // if there are more than a single delivery method to select, the value has been cleared;
      // don't show an error message to the user, until they interact with this "new" control;
      if (singleDeliveryMethod === false) {
        this.formGroup.controls.deliveryMethod.markAsUntouched();
      }
    }
  }

  setDeliveryMethodContent(): void {
    const deliveryMethod = this.dataService.getDeliveryMethodObject(
      this.formGroup.controls.deliveryMethod.value
    );

    // set the content based on the selected delivery method;
    if (deliveryMethod) {
      this.content.deliveryMethodHelpText = deliveryMethod.helpText;
      this.content.deliveryMethodDisplay = deliveryMethod.display;
      this.content.deliveryMethodType = deliveryMethod.deliveryMethodType;

      // alter the available answers for the `How many copies...` question;
      // electronic vs all others;
      if (this.commonValues.electronicDeliveryTypes.indexOf(this.content.deliveryMethodType) > -1) {
        this.values.howMany = this.values.howManyElectronic;
        this.formGroup.patchValue({
          howMany: this.values.howMany[0].value
        });
      } else {
        this.values.howMany = this.values.howManyAll;
      }

    }
  }

  getFees(): void {
    const who = this.data.form.recipient.who;
    let zeroFeeProgramId = null;
    this.isSPDESchool = this.dataService.isSPDEZeroFeePrgmEligible();
    this.isFASTSchool = this.dataService.isFASTZeroFeePrgmEligible();
    if (!this.isSPDESchool && !this.isFASTSchool && this.dataService.isZeroFeePrgmEligible()) {
      zeroFeeProgramId = who.recipientResponse.zeroFeePrgmId;
      this.isTNSchool = true;
    }
    const controls = this.formGroup.controls;
    const deliveryMethod = controls.deliveryMethod.value;
    const howMany = controls.howMany.value;
    const transcriptType = controls.transcriptType.value;
    const exchangeNetworkType = who.recipientResponse ? who.recipientResponse.exchangeNetworkType : null;
    const orderProcessFeePaidBy = this.dataService.getOrderProcessFeePaidByValue(deliveryMethod, exchangeNetworkType);

    const payload = {
      toScprofilId: this.data.schoolProfile.toScprofilId,
      toOrderId: this.data.response.orderHeader.toOrderId,
      deliveryMethodId: (deliveryMethod == null) ? '' : deliveryMethod.toString(),
      quantityId: howMany,
      xcriptTypeId: (transcriptType == null) ? '' : transcriptType.toString(),
      zeroFeePrgrmId: zeroFeeProgramId,
      feeReduceEligible: (this.isSPDESchool || this.isFASTSchool) ? this.commonValues.api.yes : null,
      orderProcessFeePaidBy: orderProcessFeePaidBy
    };

    // clear any value until we get a response;
    this.values.fees = null;

    // kill the previous takeUntil() observable;
    // so any previously made calls won't be processed;
    // just the latest one;
    this.unsubscribeFee$.next();
    this.unsubscribeFee$.complete();
    this.unsubscribeFee$ = new Subject();

    // dont check for transcriptType, it's not required;
    // but the other two are, so no reason to make a call unless you are expecting a response;
    if (deliveryMethod && howMany) {
      this.blockingIndicatorService.open();
      this.feesService
        .get(payload)
        .pipe(takeUntil(this.unsubscribeFee$))
        .subscribe((json: ApiResponseFees) => {
          const fees = json.summaryFeeInfo;
          const deliveryMethodObject = this.dataService.getDeliveryMethodObject(deliveryMethod);

          fees.deliveryFeeDescription = 'Delivery Fee';

          // if this is an electronic delivery;
          // convert the display for the delivery fee;
          if (deliveryMethodObject.deliveryMethodType === this.commonValues.api.electronic) {
            fees.deliveryFeeDescription = this.convertDeliveryMethod.get(
              fees.deliveryFeeDescription
            );
          }

          // save the converted response data;
          // this will be used for the rest of the app;
          this.values.fees = fees;
          this.blockingIndicatorService.close();
        });

    }
  }

  isDisabled(): boolean {
    const formValid = this.formGroup.valid;
    const acceptTermsValid = this.formGroup.controls.acceptTerms.value === this.values.yes;
    const feesValid = this.values.fees;
    return !(formValid && acceptTermsValid && feesValid) || (this.content.allowUploads && typeof this.formFieldFiles !== 'undefined' && this.formFieldFiles != null && this.formFieldFiles.uploader.isUploading);
  }

  previous(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy:  "prevTitle",
      ariaDescribedBy: "prevContent",
      autoFocus: false,
      data: {
        body: this.commonValues.warningLoseInformation,
        title: "Information",
        id:"prev"
      }
    }).afterClosed().subscribe(result =>{
      if(result){
        this.dataService.resetRecipientDeliveryMethod();

        this.securityService.setDeactivateWarning(false);
  
        this.router.navigate(
          [this.commonValues.routes.recipientSelect],
          this.commonValues.routes.extras
        );
      }
    });

  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  // for TN $0 fee it will show an icon which will open a popup
  showTNFeeDetailsPopUp() {
    this.openDialog(this.commonValues.zeroFeeTNDialogContent);
  }

  showPartnerNetworkInfoPopUp() {
    let partnerName = '';
    if (this.isSPDESchool) {
      partnerName = this.commonValues.SPDEPartner;
    } else if (this.isFASTSchool) {
      partnerName = this.commonValues.FASTPartner;
    }
    this.openDialog(this.commonValues.getPartnerNetworkInfoDialogContent(partnerName));

  }

  checkDisplayFpOption(deliveryType: string) {
    const isVisibleFPOptions = (!this.schoolProfile.integrationProvider && this.schoolProfile.displayFpOptions === this.commonValues.api.yes && (deliveryType === this.commonValues.api.afterDegreeIsAwarded || deliveryType === this.commonValues.api.afterGradesArePosted));
    return isVisibleFPOptions;
  }

  openDialog(content): void {
    const helpText = content;
    this.dialog.open(DialogHelpComponent, {
      width: '450px',
      ariaLabelledBy: helpText.id + "Title",
      ariaDescribedBy: helpText.id + "Content",
      autoFocus: false,
      data: {
        body: helpText.message,
        title: helpText.title,
        id: helpText.id
      }
    });
  }

  setHowManyDefaultValue(): void {

    // if only a single option exists, pre-select it;
    if (this.values.howMany.length === 1) {
      this.formGroup.patchValue({
        howMany: this.values.howMany[0].value
      });
    }

  }



  continue(): void {
    // if zeroFeeProgram eligible then set zeroFeeProgramEligible control to 'Y'
    const deliveryMethodObject = this.dataService.getDeliveryMethodObject(
      this.formGroup.controls.deliveryMethod.value
    );
    if (this.isSPDESchool || this.isFASTSchool || (this.dataService.isZeroFeePrgmEligible() && deliveryMethodObject.deliveryMethodType === this.commonValues.api.etx)) {
      this.formGroup.controls.zeroFeeProgramEligible.setValue(this.commonValues.api.yes);
    } else {
      this.formGroup.controls.zeroFeeProgramEligible.setValue(null);
    }

    // we need to clear out any previous content before saving to prevent orphaned content (like courses);
    this.dataService.resetRecipientDeliveryMethod();

    // save delivery method information on recipient object

    const specialInstructionsData = this.formGroup.value.specialInstructions;

    if (specialInstructionsData != null && specialInstructionsData !== null && specialInstructionsData.trim().length === 0) {
      this.formGroup.value.specialInstructions = null;
    }


    this.dataService.save(_cloneDeep(this.formGroup.value), 'form.recipient.delivery');
    this.dataService.save(
      {
        fees: _cloneDeep(this.values.fees)
      },
      'form.recipient'
    );

    // save file attachments on recipient object only if attachments are allowed
    if (this.content.allowUploads && typeof this.formFieldFiles !== 'undefined' && this.formFieldFiles != null) {
      this.dataService.save(_cloneDeep(this.formFieldFiles.uploadedFiles), 'form.recipient.attachments');
    }


    // if recipient is 'Me' then we prepopulate the delivery address with the user's address only if it's overnight or mail.
    const recipientIsMe = this.data.form.recipient.who.recipientType === this.commonValues.api.me;
    if (recipientIsMe) {
      //  if we edit the order info we need to populate the updated information
      // so if is not an edit order index will be null then needs to read the information from the requestor form.
      const requestor = this.data.form.recipient.index !== null ? this.data.form.recipients[this.data.form.recipient.index].address : this.data.response.student;
      if (!requestor.street1) {
        requestor.street1 = requestor.addressLine1;
        requestor.street2 = requestor.addressLine2;
      }
      switch (deliveryMethodObject.deliveryMethodType) {
        case this.commonValues.api.mail:
        case this.commonValues.api.overnight:
          const isUS = requestor.country === this.commonValues.api.us;
          const isCA = requestor.country === this.commonValues.api.ca;
          const isDomestic = isUS || isCA;

          this.dataService.save(
            {
              addressLine1: requestor.street1,
              addressLine2: requestor.street2 || null,
              zip: requestor.zip,
              city: requestor.city,
              state: isDomestic ? requestor.state : null,
              country: requestor.country,
              phone: requestor.phone,
              emailPrimary: this.data.response.student.email,
              emailConfirm: this.data.response.student.email
            },
            'form.recipient.address'
          );
          break;
        case this.commonValues.api.hold:
          this.dataService.save({
            recipient: this.dataService.getFullName()
          }, 'form.recipient.address');
          break;
        case this.commonValues.api.electronic:
          if (requestor.email !== null && requestor.email !== undefined) {
            this.dataService.save({
              emailPrimary: requestor.email,
              emailConfirm: requestor.email
            }, 'form.recipient.address');
          }
          else {
            this.dataService.save({
              emailPrimary: requestor.emailPrimary,
              emailConfirm: requestor.emailConfirm
            }, 'form.recipient.address');
          }
          break;
      }
    }

    // reset address verification values
    this.dataService.save(
      {
        isVerifiedAddress: null,
        isAckgInvalidAddress: null
      },
      'form.recipient.address'
    );

    const route = this.getNextRoute();
    if (route === this.commonValues.routes.orderReview) {
      // Clean the recipient address object otherwise when we edit if the editted recipient has recipient_address object filled,then
      // in the order review page the recipient name will populate from that object instead of the new one selected.
      // ressetting for all recipient path except "ME" path since "deliveryMethodType" attribute is required in order to populate recipient name in order review page
      if (!recipientIsMe) {
        this.dataService.resetRecipientAddress();
      }
      this.dataService.saveRecipient();
    }

    this.securityService.setDeactivateWarning(false);

    this.router.navigate([route], this.commonValues.routes.extras);
  }
}
