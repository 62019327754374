import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { pbkdf2 } from 'crypto';
import { Injectable } from "@angular/core";

// define the routes as a plain JS object so they can be imported into app-routing;
// these values are redefined below in CommonValues so we dont have to import routes throughout the app;
export const routes = {
  schoolWithFiceCode: 'school/ficecode/:ficeCode',
  schoolWithProfileID: 'school/profileid/:profileId',
  schoolSelect: 'school/select',
  schoolWelcome: 'school/welcome',


  requestorIdentification: 'requestor/identification',
  requestorAddress: 'requestor/address',
  requestorAttend: 'requestor/attend',

  recipientSelect: 'recipient/select',
  recipientDeliveryMethod: 'recipient/delivery-method',
  recipientAddress: 'recipient/address',
  recipientEmail: 'recipient/email',

  orderReview: 'order/review',
  orderConsent: 'order/consent',
  orderPayment: 'order/payment',
  orderConfirmation: 'order/confirmation',

  messageWithReason: 'message/reason/:reason',
  messageError: 'message/reason/error',
  messageNetwork: 'message/reason/network',
  messagePayment: 'message/reason/payment',
  messageTimeout: 'message/reason/timeout',

  authenticationFailure: 'authentication/failure',
  schoolRedirectficeCode: 'school/ficecode',
  schoolRedirectprofileid: 'school/profileid',

  outageMessage: 'outage/message',
  // pass in router.navigate(commands, extras) calls when we want to NOT add history to the browser;
  extras: { replaceUrl: true }
};

@Injectable()
export class CommonValues {
  address = {
    verified: 'Verified',
    partial: 'Partial',

    hintZipDomestic: '',
    hintZipInternational: '(Optional)',

    hintPhoneDomestic: '(XXX) XXX-XXXX',
    hintPhoneInternational: 'Enter digits or dashes only (591-12345678)',
    
    labelPhoneUS: 'Phone Number',
    
    labelPhone :{
      international: 'Local Recipient Phone Number',
      internationalExpress: 'International Recipient Phone Number'
    },

    hintDescValue : {
      international: 'a local',
      internationalExpress: 'an international' 
    },
    
    labelStateUS: 'State/Territory/APO',
    labelStateCA: 'Province/Territory',
    labelStateInternational: 'State/Province/Territory',
    labelState: 'State',

    validationPhoneDomestic: [
      Validators.minLength(10),
      Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
    ],
    validationPhoneInternational: [
      Validators.minLength(10),
      Validators.pattern(/^(\d+|\d+(-\d+)+)((-){0,1}(x|X){1}(-){0,1}\d+)?$/)
    ],
    validationZipCA: [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern(/^[A-Za-z]\d[A-Za-z](( )?\d[A-Za-z]\d)?$/)
    ],
    validationZipUS: [
      Validators.required,
      Validators.minLength(5),
      Validators.pattern(/^\d{5}(-\d{4})?$/)
    ],
    validationZipCNVNSG: [
      Validators.required,
      Validators.minLength(6),
      Validators.pattern(/^\d{6}$/)
    ],
    validationZipKRDESA: [
      Validators.required,
      Validators.minLength(5),
      Validators.pattern(/^\d{5}$/)
    ],
    validationZipJP: [
      Validators.required,
      Validators.pattern(/^\d{3}-?\d{4}$/)
    ],
    validationZipGB: [
      Validators.required,
      Validators.pattern(/^[\w\s]{5,8}$/)
    ],
    validationZipTW: [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern(/^\d{3,5}$/)
    ],
    validationZipNO: [
      Validators.required,
      Validators.minLength(4),
      Validators.pattern(/^\d{4}$/)
    ],
    validationZipNL: [
      Validators.required,
      Validators.pattern(/^\d{4}\s?[A-Za-z]{2}$/)
    ]
  };

  api = {
    // school profile values;
    both: 'B',
    no: 'N',
    optional: 'O',
    required: 'R',
    yes: 'Y',
    yesSMS: 'T',
    // delivery method types;
    electronic: 'E',
    etx: 'T',
    fax: 'F',
    faxMail: 'A',
    faxExpress: 'B',
    hold: 'H',
    mail: 'M',
    overnight: 'O',

    // recipient types;
    business: 'O',
    college: 'S',
    me: 'M',
    organization: 'A',

    // eligibility obor -speede schools
    obor: 'OBOR',
    speede: 'SPDE',
    fast: 'FAST',
    etxNetwork: 'ETX',
    lsac: 'LSAC',
    amcas: 'AMCAS',
    liason: 'LIAISON',
    slate: 'SLATE',

    lsacEteIdType:'LSAC_ID',
    amcasEteIdType:'AMCAS_AAMC_ID',
    liasonEteIdType:'LIAISON_CAS_TRANSCRIPT_ID',
    
    // country codes;
    ca: 'CA',
    mx: 'MX',
    us: 'US',
    oh: 'OH',
    cn: 'CN',
    kr: 'KR',
    jp: 'JP',
    de: 'DE',
    gb: 'GB',
    tw: 'TW',
    nw: 'NO',
    nl: 'NL',
    sa: 'SA',
    vn: 'VN',
    sg: 'SG',
    international: 'ZZ',

    // path types;
    free: 'F',
    pay: 'P',

    // value to check if any variable is undefined
    undefined: 'undefined',

    // XmitCode deliveryMethod-Countries
    mailUsa: 'MU',
    expressUsa: 'XU',
    mailCaMex: 'MC',
    expressCaMex: 'XC',
    mailInternational: 'MI',
    expressInternational: 'XI',
    rushMailUsa: 'RMU',
    rushExpressUsa: 'RXU',
    rushMailCaMex: 'RMC',
    rushExpressCaMex: 'RXC',
    rushMailInternational: 'RMI',
    rushExpressInternational: 'RXI',
    rushCertifiedMailUsa:'RCU',
    FedExInternational: 'FI',
    FedExCanadaMexico: 'FC',
    FedExUsa: 'FU',
    RushFedExInternational: 'RFI',
    RushFedExCanadaMexico: 'RFC',
    RushFedExUsa: 'RFU',
    certifiedMailUsa:'CU',



    // transcript when options;
    afterCourseIsCompleted: 'R',
    afterDegreeIsAwarded: 'D',
    afterDegreeIsPosted: 'S',
    afterGradesAreChanged: 'C',
    afterGradesArePosted: 'G',
    afterHonorsArePosted: 'H',
    holdForCurrentTermSchedule: 'U',
    holdForFallTermGrades: 'F',
    holdForSpringTermGrades: 'T',
    now: 'N',


    // recipient order status
    canceled: 'C',
    completed: 'Z',
    created: 'A',
    inProcess: 'L',
    sent: 'D',
    pending: 'P',
    placed: 'P',

    // file formats
    pdf: 'PDF',
    xml: 'XML',
    edi: 'EDI',

  };

  //allowed student name
  studentName = {
    livedName: {
      code: "L",
      name: "Lived Name"
    },
    preferredName: {
      code: "P",
      name: "Preferred Name"
    },
    none: {
      code: "N",
      name: "None"
    }
  }


  popupDialog = {
    okBtn: 'okBtnClicked',
  };
  autocomplete = {
    // option to display in the autocomplete when there are no matches;
    // disabled because it is not a valid option for the user to choose, they must type in a different value;
    noMatch: {
      name: 'There is no match for the school entered',
      disabled: true // make sure this value isn't selectable;
    },

    // option to display  when adding a `Not in List` value to an autocomplete form field;
    // this is enabled to allow the form to trigger a `Not in List` INPUT to display;
    notInList: {
      schlName: 'Not in List'
    },

    custom: {
      labelSchool: null,
      labelSchoolLoading: 'Loading Schools',
      backSearchLinkLabel: 'Back',

      requestor: {
        defaultLabelSchoolLoaded: 'Enter the school you want to request your transcript from',
        defaultSearchLinkLabel: 'Advanced Keyword Search',
        defaultTitle: 'Select School',
        advTitle: 'Order a Transcript Advanced Keyword Search',
        advLabelSchoolLoaded: 'Enter a keyword to find your school',
      },
      recipient: {
        advLabelSchoolLoaded: 'Enter a keyword to find and select the school you are sending your transcript to',
        labelSchoolLoaded: 'Enter and select the school you are sending your transcript to',
        searchLinkLabel: 'Advanced keyword search to find school',
      },
    }
  };

  creditCardRegEx = {
    americanExpress: new RegExp(/^3[47][0-9]{13}$/),
    dinersClub: new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/),
    discover: new RegExp(/^6(?:011|5[0-9]{2})[0-9]{12}$/),
    jcb: new RegExp(/^(?:2131|1800|35\d{3})\d{11}$/),
    mastercard: new RegExp(
      /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
    ),
    visa: new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/)
  };

  dateFormat = {
    display: 'MM/DD/YYYY hh:mm A',
    input: 'MM/DD/YYYY',
    output: 'YYYY-MM-DD'
  };

  // international ZIP value to be used on POST;
  // also to be filtered out on the payment page when the user populates the form with saved data;
  defaultZip = 'N/A';

  // variables used to configure how many times Payment Error messages should be shown on Payment page for invalid credit card info;
  payeezyCount = 0;
  payeezyCountLimit = 2;
  logoutContextPath= '/stauth/logout';

  // regex used to validate an email has a tld;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // value that triggers an electronic delivery method (route and display values);
  electronicDeliveryTypes = [this.api.electronic, this.api.etx];

  // timeout values used when working with the loading indicator (progress-bar);
  loading = {
    delay: 2000,
    interval: 500
  };

  // configuration to disable reactive form fields;
  nullDisabled = {
    value: null,
    disabled: true
  };

  routes = routes;

  select = {
    // used when adding a Not in List value to a SELECT form field;
    notInList: {
      name: 'Not in List',
      value: 'NOT IN LIST'
    }
  };

  warningLoseInformation =
    'You will lose the information entered on this page. Are you sure you want to continue back?';

  onPhoneBlurPopupInfo = 
  'Please confirm that you have entered an international phone number for delivery. The transcript may be returned to your school if the carrier cannot contact the recipient.'; 

  applicationType = {
    studentSelfService: 'SSS',
    myHub: 'H',
    saml: 'O',
  };

  applicationURL = {
    trackOrderContextRoot: '/TrackOrder'
  };

  routeRestart: 'restart';

  searchType = {
    wildCardSearch: 'W',
    startsWithSearch: 'S'
  };

  addressValidationMessages = {
    missingPrimary: 'Invalid Address: Primary address is not found.',
    missingPrimaryBuildingNumber: 'Invalid Address: Street number is missing.',
    internationalMissingStreetNumber: 'Invalid Address: Street number or name is missing or invalid.',
    cityNotFound: 'Invalid Address: City/State/Zip is not found for this address.',
    internationalCityNotFound: 'Invalid Address: Primary address is not found for this City/Province/Postal Code.',
    addressAmbigous: 'Invalid Address: Address matched multiple addresses',
    addressAmbigousInternational: 'Invalid Address: Multiple Addresses Found.',
    secondaryAddress: 'Invalid Address: APT/Suite number is missing or invalid.',
    secondaryInternationalAddress: 'Invalid Address: Building, Street or APT/Suite number is missing or invalid.',
    addressInvalid: 'Invalid Address: No matches found.',
    notEnoughInput: 'Not all components of the Address are provided, please verify',
    privateMailBox: 'Could not confirm private mailbox',
    ruralAddressMissingSecondaryNumber: 'Invalid Address: PO, RR, or HC box number is missing or invalid.',
    newAddressSuggested: 'The entered address has been formatted to USPS standards. Please make a selection or edit the entered address.',
    newInternationalAddressSuggested: 'The entered address has been formatted to the delivery standards. Please make a selection or edit the entered address.',
    invalidAddressMessage: 'USPS was unable to find the address you entered as deliverable.  Addresses on a university or college campus  are controlled by the school and may not be registered with the US Postal Service. Please check with the recipient for an alternate or updated address.',
    invalidAddressMessageInternational: 'We are unable to find the address you entered as deliverable. Addresses on a university or college campus  are controlled by the school and may not be registered. Please check with the recipient for an alternate or updated address.',
    invalidAddressAndCannotContinue: 'USPS was unable to find the address you entered as deliverable for Express Mail. Please edit the address or change the delivery method to a non-Express Mail option if you need to use this address.Addresses on a university or college campus  are controlled by the school and may not be registered with the US Postal Service. Please check with the recipient for an alternate or updated address.',
    invalidInternationalAddressAndCannotContinue: 'We are unable to find the address you entered as deliverable for Express Mail. Please edit the address or change the delivery method to a non-Express Mail option if you need to use this address.Addresses on a university or college campus are controlled by the school and may not be registered. Please check with the recipient for an alternate or updated address.',
    addressSuggestedAndCannnotContinue: 'You must provide a recognized USPS address for Express Mail deliveries. If you want to use the address you entered, you will need to change the delivery method to a non-Express Mail option.',
    internationalAddressSuggestedAndCannnotContinue: 'You must provide a recognized address for Express Mail deliveries. If you want to use the address you entered, you will need to change the delivery method to a non-Express Mail option.',
    acknowlelgeAddressUS: 'The entered address could not be found in the US Postal Service database. By selecting "Yes", you are acknowledging that your order may not be deliverable. It is your responsibility to provide an accurate address, Do you want to continue with this address?',
    acknowlelgeAddressInternational: 'The entered address could not be found. By selecting "Yes", you are acknowledging that your order may not be deliverable. It is your responsibility to provide an accurate address. Do you want to continue with this address?',
    securePrintInvalidAddressUS: 'You must provide a recognized US Postal Service address for Express/Certified Mail deliveries. The US Postal Service was unable to find the address you entered as deliverable. Please enter another address or change the delivery method to a non-Express/non-Certified Mail option if you need to use this address. Addresses on a university or college campus are controlled by the school and may not be registered with the US Postal Service. Please check with the recipient for an alternate or updated address.',
    securePrintInvalidAddressInternational: 'You must provide a recognized address for Express Mail deliveries. We are unable to find the address you entered as deliverable. Please enter another address or change the delivery method to a non-Express Mail option if you need to use this address. Addresses on a university or college campus are controlled by the school and may not be registered. Please check with the recipient for an alternate or updated address.',
  };

  zeroFeeTNDialogContent= {
    message: "<p>The fees have been waived for this recipient order under the Tennessee Electronic Transcript Exchange program.</p>",
    title: "Tennessee Electronic Transcript Exchange",
    id: "modalHeadingZeroFeeTN"
  }
  
  partnerNetworkInfoDialogContent= {
      message: "<p>The school fees have been waived for this order under University of Phoenix network partner program.</p>",
      title: "Partner Network Program",
      id: "modalHeadingPartnerNetwork"
    }

  excludedStateValuesForCertifiedMail = ['AK', 'HI'];
  excludedStateCategoriesForCertifiedMail = ['Territories', 'Army Post Offices'];
  invalidDeliveryMethodWarningMessageCertifiedMailUS = 'Certified Mail/United States cannot be delivered to your selected State/Territory/APO. If you wish to send your transcript to the selected state, please select \'Previous\' and request a different delivery method for this recipient address.';

  httpStatusCodesSystemOutage = [500, 501, 502, 503, 504, 506, 507, 509, 510, 400, 401, 402, 403 , 404, 405, 406, 407, 408, 409, 410, 411, 412, 413,414, 415, 416, 417];

  SPDEPartner = 'University of Phoenix';

  FASTPartner = 'Florida FASTER';

  constructor() { }

  getServiceErrorMessage(item: String) {
    const subNetworkType = item === this.api.liason ? 'CAS' : item;
    const dialogContent = `<p> Please re-enter your ${subNetworkType} Transcript ID. We apologize for the inconvenience.</p>`;
    return dialogContent;
  }

  getPartnerNetworkInfoDialogContent(partnerName: String) {

    const partnerNetworkInfoDialogContent= {
      message: "<p>The school fees have been waived for this order under the " + partnerName+ " network partner program.</p>",
      title: "Partner Network Program",
      id: "modalHeadingPartnerNetwork"
    }
    return partnerNetworkInfoDialogContent;
  }
}
